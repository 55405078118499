import { useQuery } from '@tanstack/react-query';
import { getTemplates } from 'apis/index_template';
import { useUser } from 'contexts/user-context';
import { useMemo } from 'react';
import { WBSourceType } from 'types/types';
import { getDashboardSegments } from 'apis/dashboard';
import { getIndexFields } from 'apis/index_fields';
import {
  flatGroupFields,
  getAllGroups,
  removeSourceAndMergeGroups,
} from 'components/settings2/SettingsFields';
export const VARIABLE_GROUP_KEY = '$variables';

type ParamsType = { source: WBSourceType | 'AllFieldsWithoutGroup' };
const useQBFields = ({ source }: ParamsType) => {
  const { curIndex } = useUser();
  const { data: dataFields, isLoading: isLoadingFields } = useQuery(
    // Avoid cache for datafields been reset: APP-2901/edited-recipe-is-lost-when-user-changes-changes-datatable-columns
    // Todo: some other better solution
    [curIndex, 'IndexFields2'],
    () => getIndexFields(curIndex!.value),
    { enabled: Boolean(curIndex), staleTime: Infinity }
  );

  const templateSource = source === 'site' ? 'page' : source;
  let { data: recipesBack, isLoading: isLoadingRecipes } = useQuery(
    [curIndex, 'WBQBRecipe', templateSource],
    () => getTemplates(curIndex!.value, templateSource),
    {
      enabled: Boolean(curIndex) && source !== 'AllFieldsWithoutGroup',
      staleTime: Infinity,
    }
  );

  const { data: pageSegs, isLoading: isLoadingPageSegs } = useQuery(
    [curIndex, 'DashboardSegments'],
    () => getDashboardSegments(curIndex!.value),
    { enabled: Boolean(curIndex), staleTime: Infinity }
  );

  let fields = useMemo<any>(() => {
    const fields: any = {};
    if (dataFields) {
      if (source === 'AllFieldsWithoutGroup') {
        const groups = removeSourceAndMergeGroups(dataFields);
        const fieldSet = new Set<string>();
        for (const group of groups) {
          for (const _field of group.fields) {
            if (fieldSet.has(_field.field)) continue;
            fieldSet.add(_field.field);
            let type = _field.type;
            let listValues: any = undefined;
            if (type === 'enum') {
              type = 'select';
              if (_field.meta?.options) {
                listValues = _field.meta?.options;
              }
            }
            fields[_field.field] = {
              label: _field.label,
              tooltip: _field.helpText,
              type,
              valueSources: ['value'],
            };
            if (listValues) {
              fields[_field.field]['fieldSettings'] = {};
              fields[_field.field]['fieldSettings']['listValues'] = listValues;
            }
          }
        }
        return fields;
      }
      const allFieldGroups = getAllGroups(dataFields, source);
      for (const group of allFieldGroups) {
        const fieldGroup: any = {
          label: group.label,
          tooltip: group.helpText,
          type: '!struct',
        };
        if (source === 'finished_linking') {
          group.fields = flatGroupFields(group.fields);
        }
        const subfields: any = {};
        for (const _field of group.fields) {
          subfields[_field.field] = {
            label: _field.label,
            tooltip: _field.helpText,
            type: 'text',
            valueSources: ['value'],
            preferWidgets: ['textarea'],
          };
          if (_field.type === 'number') {
            subfields[_field.field].type = 'number';
          }
          if (_field.type === 'date') {
            subfields[_field.field].type = 'date';
          }
          if (_field.type === 'boolean') {
            subfields[_field.field].type = 'boolean';
          }
          if (_field.type === 'enum') {
            subfields[_field.field].type = 'select';
            subfields[_field.field]['operators'] = [
              'select_equals',
              'select_not_equals',
              'is_null',
              'is_not_null',
            ];
            if (_field.meta?.options) {
              subfields[_field.field]['fieldSettings'] = {
                listValues: _field.meta?.options,
              };
            }
          }
        }
        fieldGroup['subfields'] = subfields;
        fields[group.group] = fieldGroup;
      }
    }
    if (recipesBack && recipesBack.length > 0) {
      const recipeBackInitOptions = recipesBack.map((item: any) => ({
        value: item.id,
        label: item.name,
      }));
      fields['template'] = {
        label: 'satisfies recipe',
        type: 'select',
        valueSources: ['value'],
        fieldSettings: {
          listValues: recipeBackInitOptions.map((item: any) => ({
            value: item.value.toString(),
            title: item.label,
          })),
        },
      };
      fields['not-template'] = {
        label: 'not satisfies recipe',
        type: 'select',
        valueSources: ['value'],
        fieldSettings: {
          listValues: recipeBackInitOptions.map((item: any) => ({
            value: item.value.toString(),
            title: item.label,
          })),
        },
      };
    }
    if (
      pageSegs &&
      pageSegs.length > 0 &&
      (source === 'page' || source === 'site')
    ) {
      const fieldSettings = {
        listValues: pageSegs.map((item: any) => ({
          value: item.id,
          title: item.name,
        })),
      };
      fields['page-segment'] = {
        label: 'url in segment',
        type: 'select',
        valueSources: ['value'],
        fieldSettings,
      };
      fields['not-in-page-segment'] = {
        label: 'url not in segment',
        type: 'select',
        valueSources: ['value'],
        fieldSettings,
      };
      fields['starts-with-page-segment'] = {
        label: 'url starts with segment',
        type: 'select',
        operators: ['select_equals'],
        valueSources: ['value'],
        fieldSettings,
      };
      fields['not-starts-with-page-segment'] = {
        label: 'url not starts with segment',
        type: 'select',
        operators: ['select_equals'],
        valueSources: ['value'],
        fieldSettings,
      };
    }
    return fields;
  }, [recipesBack, pageSegs, dataFields, source]);

  const isLoading = isLoadingFields || isLoadingPageSegs || isLoadingRecipes;

  return { fields, isLoading, isLoadingFields };
};

export default useQBFields;
